<template>
  <v-container fill-height fluid text-xs-center>
    <v-card class="fill-height container--fluid" width="100vw" flat outlined>
      <v-card-title>
        <v-col sm="12" lg="6">Notificaciones</v-col>
        <v-col sm="12" lg="6" class="pt-0 pb-0">
          <v-text-field
              v-model="searchItem"
              outlined
              label="Buscar"
              placeholder="Buscar"
              class="mr-0 ml-auto"
              @keyup="search">
          </v-text-field>
        </v-col>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <div v-if="loading">
          <v-skeleton-loader class="mb-1" type="image" height="3.3rem" width="100%"></v-skeleton-loader>
          <v-skeleton-loader class="mb-1" type="image" height="3.3rem" width="100%"></v-skeleton-loader>
          <v-skeleton-loader class="mb-1" type="image" height="3.3rem" width="100%"></v-skeleton-loader>
          <v-skeleton-loader class="mb-1" type="image" height="3.3rem" width="100%"></v-skeleton-loader>
          <v-skeleton-loader class="mb-1" type="image" height="3.3rem" width="100%"></v-skeleton-loader>
          <v-skeleton-loader type="image" height="3.3rem" width="100%"></v-skeleton-loader>
        </div>
        <v-expansion-panels v-else>
          <v-expansion-panel
              v-for="(i, key) in message"
              :key="key"
              @change="()=>change(i)"
              class="expansion-custom"
          >
            <v-expansion-panel-header>
              <v-icon class="icon-custom icon-custom-not-read" v-if="i.read === false">mdi-email</v-icon>
              <v-icon class="icon-custom" v-else>mdi-email-open</v-icon>
              <div>
                <label class="ml-10 pl-1" >{{i.title}}</label>
                <v-subheader class="subheader-custom ml-7">{{ i.created_at | toCurrency}}</v-subheader>
              </div>
              <div>
                <v-btn
                    v-if="i.document"
                    dark
                    icon
                    color="primary"
                    class="ma-0 text--darken-1 btn-document"
                    elevation="0"
                    v-on:click="openDocument(i.document)">
                  <v-icon>mdi-file-document</v-icon>
                </v-btn>
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-divider></v-divider>
              <label class="d-block mt-2"><strong>Enviado por:</strong> {{i.user.name}} {{i.user.last_name}}</label>
              <p>{{i.message}}</p>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import userService from '@/providers/UsersService';
import Pusher from "pusher-js";

export default {
  name: "NotificationComponent",
  data: () => ({
    message:[],
    obj: {
      id:0,
      type:"",
      full: true
    },
    aux: [],
    searchItem: "",
    loading: true,
    pusher:  new Pusher('13e4db1337e9189ec1aa', {
      cluster: 'us2'
    }),
    channel: null,
  }),
  methods:{
    search(){
      this.message = this.aux.filter((e)=>e.message.toLowerCase().includes(this.searchItem.toLowerCase()));
    },

    change(notification){
      if(!notification.read){
        userService.readNotification(notification).then(resp=>{
          if(resp.success){
            this.message.forEach(e=>{
              if(e.id==notification.id){
                e.read = true;
              }
            });
            this.$store.state.notifications = this.$store.state.notifications.filter(e=>e.id!=notification.id);
          }
        }).catch(err=>console.log(err));
      }
    },

    openDocument(document) {
      window.open(document, "_blank");
    },
  },
  filters:{
    toCurrency(value){
      return (new Date(value)).toLocaleDateString('es-ES', { year: 'numeric', month: 'long', day: 'numeric', timeZone: 'UTC' });
    },
  },
  mounted() {
    userService.getNotificationInvestor().then((response) => {
      if(response.success){
        this.message = response.value.map((e)=>{
          return {
            ...e.notification,
            read: e.read,
          }
        });
        this.aux = [...this.message];
      }
    }).catch(err=>console.log(err)).finally(()=>{
      this.loading = false;
    });

    this.channel = this.pusher.subscribe("new-notifications");
    this.channel.bind(this.$store.state.user.email, (data)=> {
      const msg = {
        id: data.id,
        title: data.title,
        message:data.message,
        document: data.document,
        created_at: data.created_at,
        read: false,
        user: {
          id: data.user_id,
          name: data.user_name,
          last_name: data.user_lastname,
        }
      }
      this.message = [
        msg,
        ...this.message
      ];
      this.aux = [
          msg,
        ...this.aux
      ];
    });
  }
}
</script>

<style scoped>
  .icon-custom{
    display: inline-block !important;
    position: absolute;
    font-size: 2.5rem;
  }
  .icon-custom-not-read{
    color: #ded06f;
  }
  .subheader-custom{
    height: auto !important;
    padding-top: 0.1rem !important;
  }
  .expansion-custom{
    margin-top: 1px !important;
  }
  .btn-document{
    margin-left: auto !important;
    margin-right: 0.3rem !important;
    display: flex;
  }
</style>