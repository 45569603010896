<template>
  <NotificationComponent/>
</template>

<script>
import NotificationComponent from '@/components/investors/NotificationComponent';

export default {
  name: "InvestorNotificationsView",
  components: {NotificationComponent}
}
</script>

<style scoped>

</style>